import Plane from '@/public/animations/face-loading.json';
import Loading from '@/public/animations/loading-loop.json';
import Error from '@/public/animations/error-loop.json';
import Check from '@/public/animations/check-mark.json';
import PictureLoading from '@/public/animations/picture-loading.json';
import Lottie from 'react-lottie-player';

interface AnimationsProps {
  name: 'faceLoading' | 'loading' | 'error' | 'check' | 'pictureLoading';
  className?: string;
}

export const Animation = (props: AnimationsProps) => {
  const { name, className = '' } = props;

  const animations: Record<string, unknown> = {
    faceLoading: Plane,
    loading: Loading,
    error: Error,
    check: Check,
    pictureLoading: PictureLoading,
  };

  const option = animations[name] || '';
  return <Lottie className={className} loop animationData={option} play />;
};
